import { Card } from 'react-bootstrap'
import PayPalButton from './PayPalButton';

export default function OnePayButton(props) {

  return (
    <Card style={{border: 'none'}}>
      <Card.Body>
        <Card.Title>${props.amount}.00</Card.Title>
        <Card.Text>
          Use the button below to donate ${props.amount}.00 to our cause.
        </Card.Text>
        <PayPalButton price={props.amount + .00} />
      </Card.Body>
    </Card>
  )
}
/* 
MersaTech Code
        <Form action="https://mersatech.transactiongateway.com/cart/cart.php" method="POST">
            <input type="hidden" name="amount" value={value} />
            <input type="hidden" name="key_id" value="14003666" />
            <input type="hidden" name="action" value="process_variable" />
            <input type="hidden" name="order_description" value="One Time Donation" />
            <input type="hidden" name="language" value="en" />
            <input type="hidden" name="customer_receipt" value="true" />
            <input type="hidden" name="hash" value="action|order_description|701d697b75ed72e258ae836fa570c803" />
            <Button type="submit" name="submit">Submit</Button>
*/