import { useState, useEffect } from 'react'
import { Container, Form, Button, Card } from 'react-bootstrap';
import { addDoc, collection, deleteDoc, getDocs, doc } from 'firebase/firestore';
import { db, auth } from '../../Firebase';
import { useNavigate } from 'react-router-dom' 
import { FaTrash } from "react-icons/fa";
import { motion } from 'framer-motion';

export default function BlogUploadForm() {
    const [title, setTitle] = useState("")
    const [postText, setPostText] = useState("")
    const [validated, setValidated] = useState(false);
    const [blogList, setBlogList] = useState([])
    const [dataFetched, setDataFetched] = useState(false); // Track if data has been fetched
    const navigate = useNavigate()

    const postsCollectionRef = collection(db, "posts")

    useEffect(() => {
        const getPosts = async () => {
          const data = await getDocs(postsCollectionRef);
          setBlogList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
          console.log(blogList);
        };
    
        if (!dataFetched) {
          getPosts();
          setDataFetched(true);
        }
      }, [dataFetched]); // Make sure to include postsCollectionRef as a dependency
    

    const createPost = async () => {
        try {
            await addDoc(postsCollectionRef, {
                title, 
                postText,
                author: auth.currentUser.displayName, 
                author_id: auth.currentUser.uid,
            })
            setValidated(true);
            alert("Success! You've added a new post!")
            handleReset()
            navigate('/newsletter')
        } catch (error) {
            console.log(error)
            alert('Sorry but there seems to be an error. Refresh the page and try again.')
        }
    }

    const deletePost = async (id) => {
        try {
            const postDoc = doc(db, "posts", id);
            await deleteDoc(postDoc);
            refreshList()
        } catch (error) {
            console.log(error)
        }
    }

    const handleReset = () => {
        setTitle("")
        setPostText("")
        setValidated(false);
    }

    const refreshList = async () => {
        const data = await getDocs(postsCollectionRef)
        setBlogList(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
        console.log(blogList)
    }

    const formatPostText = (text) => {
        if (!text) return null;
        const paragraphs = text.split('\n');
        return paragraphs.map((paragraph, index) => <p key={index}>{paragraph}</p>);
    };

  return (
    <Container>
        <Container>
        <Card style={{maxWidth: '50rem'}}>
            <Card.Body>
                <Form validated={validated}>
                    <Form.Group className="mb-3" controlId="formBasicTitle">
                        <h3>Blog Upload</h3>
                        <p>Have something you need the world to hear? Start here!</p>
                        <Form.Label>Blog Title</Form.Label>
                        <Form.Control type="text" placeholder="Enter your Blog Post Title" onChange={(e) => setTitle(e.target.value)} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicMessage">
                        <Form.Label>Blog Content</Form.Label>
                        <Form.Control 
                            onChange={(e) => setPostText(e.target.value)}
                            as="textarea"
                            style={{ height: '100px' }}
                            type="text" 
                            placeholder="Enter your Blog Post Content" />
                    </Form.Group>
                    <Button variant="primary" onClick={createPost}>
                        Submit
                    </Button>
                </Form>
            </Card.Body>
        </Card>
        </Container>
        <Container className='p-0 mt-4' style={{borderTop: 'solid black 1px'}}>
            <h5>Current Blogs</h5>
            {blogList ? 
            <>
            <Container>
            {blogList.map((post) => {
              return (
                <Container key={post.id} fluid className="p-0">
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.5 }}>
                    <Container fluid>
                      <Card style={{maxWidth: '30rem', maxHeight: '35rem'}}>
                        <Card.Body>
                            <Card.Title>{post.title}</Card.Title>
                            <Card.Subtitle>{post.author}</Card.Subtitle>
                            <Card.Text>{formatPostText(post.postText)}</Card.Text>
                        </Card.Body>
                      </Card>
                      <Container className="d-flex align-items-center justify-content-left">
                        <Button variant="danger" className='w-auto mt-2' onClick={() => deletePost(post.id)}>
                            <FaTrash />
                        </Button>
                      </Container>
                    </Container>
                  </motion.div>
                </Container>
              );
            })}
          </Container>
            </>
            :
            <></>
            }
        </Container>
    </Container>
  )
}
