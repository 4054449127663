import React from 'react'
import { Container, Card } from 'react-bootstrap'
import AccordionForm from '../Payment/AccordionForm'

export default function DonationAd() {
  return (
    <Container>
        <Card className='mt-3 mb-3 p-2'> 
            <Card.Body>
                <h4>One Time Payment</h4>
                <p>To make a one time donation to our organization, enter the amount you want to donate below.</p>
                <AccordionForm />
            </Card.Body>
        </Card>
    </Container>
  )
}
