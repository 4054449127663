import { Container, Image } from 'react-bootstrap'
import { motion } from 'framer-motion'
import GPSlogo from '../../images/GPSlogo.png'
import './GPS.css'

export default function GPSHero() {
  return (
    <Container>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: .5 }}>
        <Container className='d-flex align-items-center justify-content-center '>
            <Image src={GPSlogo} className='img-fluid' id='gps-logo' />
        </Container>
        </motion.div>
    </Container>
  )
}
