import { Container, Form, Button } from 'react-bootstrap'
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import './Contact.css'

export default function ContactForm() {
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_7g1j3ep', 'template_566w2f4', form.current, '4UhXRUE3oDZaYCjmV')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };

  return (
    <Container id='form-container' className='bodyFont mt-2' style={{maxWidth: '40rem'}}>
        <h2>Contact Us</h2>
        <p>Reach out to use for any questions or comments. We love to hear from our supporters!</p>
        <Form className='w-100' onSubmit={sendEmail} ref={form}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control type="name" name="user_name" placeholder="Enter your name" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" name="user_email" placeholder="Enter your email" />
                <Form.Text className="text-muted">
                We'll never share your email with anyone else.
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicMessage">
                <Form.Label>Your Message</Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder="Leave a message."
                    name="message"
                    style={{ height: '100px' }}
                    />
            </Form.Group>
            
            <Button variant="primary" type="submit" value="Send" className='w-100'>
                Submit
            </Button>
        </Form>
    </Container>
  )
}
