import { useEffect, useState } from 'react'
import { Container, Button, Card, ListGroup } from 'react-bootstrap'
import { collection, deleteDoc, getDocs, doc } from 'firebase/firestore'
import { db } from '../../Firebase'
import { UserAuth } from '../../context/AuthContext'
import { motion } from 'framer-motion'
import ApplicantModal from './ApplicantModal'

export default function DashboardApplicationList() {
    const [applicationList, setApplicationList] = useState(null)
    const applicationCollectionRef = collection(db, "membership")
    const {user} = UserAuth()

    useEffect(() => {
        const getApplicants = async () => {
            const data = await getDocs(applicationCollectionRef)
            setApplicationList(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
            console.log(applicationList)
        }
        getApplicants()
    }, [!applicationList])

    const deleteApplication = async (id) => {
        try {
            const applicationDoc = doc(db, "membership", id);
            await deleteDoc(applicationDoc);
            refreshList()
        } catch (error) {
            console.log(error)
        }
    }

    const refreshList = async () => {
        const data = await getDocs(applicationCollectionRef)
        setApplicationList(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
        console.log(applicationList)
    }

    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) return text;
        return text.slice(0, maxLength) + '...';
      };

    const renderSubmittedAt = (timestamp) => {
        if (!timestamp) {
            return "Date Unknown";
        }
        
        const date = new Date(timestamp); // Convert milliseconds to a Date object
        return `Submitted on ${date.toDateString()} at ${date.toLocaleTimeString()}`;
    };

    if(!applicationList) {
        return (
            <h1>Loading</h1>
                )
    }

  return (
    <Container style={{borderTop: 'solid black 1px'}}>
        <h5>Current Applicants</h5>
        {applicationList.map((applicant) => {
            return (
            <Container key={applicant.id} className='p-1'>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: .5 }}>
                <Container>
                {user ? (
                      <>
                      <Container fluid className='p-0'>
                        <Card style={{maxWidth: '50rem'}}>
                            <Card.Body>
                            <Card.Title>Altered Applicant: <br /> <span className='mt2' style={{color: 'orange', fontSize: '1.5rem'}}>{applicant.first_name} {applicant.last_name}</span></Card.Title>
                                <Card.Text className='mt-1'>Submission Date: {" "}
                                <span style={{textDecoration: 'underline'}}>{renderSubmittedAt(applicant.submittedAt)}</span>
                                </Card.Text>
                                <Card.Text>Age: {applicant.age}</Card.Text>            
                                <Card.Text>Bio: <br /> <span className='mt-2'>{truncateText(applicant.bio, 100)}</span></Card.Text>
                                <ListGroup>
                                    <ListGroup.Item>Current School: {applicant.current_school_name}</ListGroup.Item>
                                    <ListGroup.Item>Future School: {applicant.future_school_name}</ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                        </Card>
                        <Container className="mt-4">
                        <Button className='m-1' variant='danger' onClick={() => {
                            deleteApplication(applicant.id);
                            }}>Delete
                        </Button>
                        <ApplicantModal 
                            firstName={applicant.first_name} 
                            lastName={applicant.last_name} age={applicant.age} 
                            grade={applicant.age} bio={applicant.bio} 
                            goals={applicant.goals} address={applicant.address} 
                            phone={applicant.phone} 
                            currentSchool={applicant.current_school_name}
                            futureSchool={applicant.future_school_name}
                            preventions={applicant.preventions}
                            challenges={applicant.challenges}
                            />
                        </Container>
                        </Container>
                      </>
                    )
                  : (
                    <>
                    </>
                  )
                  }
                </Container>
                </motion.div>
            </Container>
            )
        })}
    </Container>
  )
}
