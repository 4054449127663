import { useEffect, useState } from 'react'
import { listAll, ref, getDownloadURL } from 'firebase/storage'
import { storage } from '../../Firebase'
import { motion } from 'framer-motion'
import { Container, Row, Col } from 'react-bootstrap'
import GalleryCard from './GalleryCard'
import uuid from 'react-uuid'

export default function GalleryList() {
    const [galleryList, setGalleryList] = useState([])

    const galleryListRef = ref(storage, "images/")

    useEffect(() => {
        listAll(galleryListRef)
        .then((res) =>{
            res.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setGalleryList((prev) => [...prev, url])
                    console.log(item, galleryList)
                })
            })
        })
    }, [])

    if(galleryList.length === 0) {
        return (
            <h2>Loading...</h2>
        )
    }

  return (
    <Container fluid>
        <Container fluid className='d-flex align-items-center justify-content-center'>
        <Row fluid>
        {galleryList.map(url => {
            return (
                    <Col xs={12} md={6} key={uuid()} className='p-0'>
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: .5 }}>
                        <GalleryCard src={url} />
                    </motion.div>
                    </Col>
            )
    })}
        </Row>
        </Container>
    </Container>
  )
}
