import Accordion from 'react-bootstrap/Accordion';
import OnePayButton from './OnePayButton';

export default function AccordionForm() {
  return (
    <Accordion className='w-100'>
      <Accordion.Item eventKey="0">
        <Accordion.Header>$200.00</Accordion.Header>
        <Accordion.Body>
        <OnePayButton amount={200.00} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>$100.00</Accordion.Header>
        <Accordion.Body>
        <OnePayButton amount={100.00} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>$50.00</Accordion.Header>
        <Accordion.Body>
        <OnePayButton amount={50.00} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>$25.00</Accordion.Header>
        <Accordion.Body>
        <OnePayButton amount={25.00} />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>$10.00</Accordion.Header>
        <Accordion.Body>
        <OnePayButton amount={10.00} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
