import { useEffect, useState } from 'react'
import { listAll, ref, getDownloadURL } from 'firebase/storage'
import { storage } from '../../Firebase'
import { motion } from 'framer-motion'
import { Container, Row, Col, Image } from 'react-bootstrap'
import uuid from 'react-uuid'

export default function EventImageList() {
    const [eventList, setEventList] = useState([])

    const eventListRef = ref(storage, "events/")

    useEffect(() => {
        listAll(eventListRef)
        .then((res) =>{
            res.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setEventList((prev) => [...prev, url])
                    console.log(item, eventList)
                })
            })
        })
    }, [])

    if(eventList.length === 0) {
        return (
            <Container>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: 1 }}>
                <p align='center'>Stay tuned for the next event!</p>
                </motion.div>
            </Container>
            )
    }

  return (
    <Container fluid className='d-flex align-items-center justify-content-center'>
        {eventList.map(url => {
            return (
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: .5 }}>
                    <Container fluid className='p-0' key={uuid()}>
                        <Image src={url} className='img-fluid'  />
                    </Container>
                    </motion.div>
            )
        })}
    </Container>
  )
}
