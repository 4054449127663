import Container from 'react-bootstrap/Container'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Navigation from './components/Navigation/Navigation';
import Home from './pages/Home';
import Membership from './pages/Membership';
import Blog from './pages/Blog';
import Events from './pages/Events';
import Gallery from './pages/Gallery';
import GPS from './pages/GPS';
import SignIn from './pages/SignIn';
import Dashboard from './pages/Dashboard';
import Error from './pages/Error'
import Footer from './components/Footer/Footer';
import { AuthContextProvider } from './context/AuthContext';
import Donations from './pages/Donations';
import ThankYou from './pages/ThankYou';
import PasswordReset from './pages/PasswordReset';
import Applications from './pages/Applications';

function App() {
  return (
    <AuthContextProvider>
    <Container fluid className='p-0 m-0'>
      <Router> 
        <Navigation />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/application' element={<Membership />} />
          <Route path='/newsletter' element={<Blog />} />
          <Route path='/events' element={<Events />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/gps' element={<GPS />} />
          <Route path='/donations' element={<Donations />} />
          <Route path='/signin' element={<SignIn />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/applications' element={<Applications />} />
          <Route path='/thank-you' element={<ThankYou />} />
          <Route path='/reset-password' element={<PasswordReset />} />
          <Route path='/*' element={<Error />} />
        </Routes>
        <Footer />
      </Router>
    </Container>
    </AuthContextProvider>
  );
}

export default App;
