import { Modal, Button, Container, Card, ListGroup, Row, Col } from 'react-bootstrap'
import { useState } from 'react';

export default function ApplicantModal(props) {
    const [show, setShow] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <Button variant="outline-primary" onClick={handleShow}>
          View Full Application
        </Button>
  
        <Modal show={show} fullscreen={fullscreen} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Applicant Name: {props.firstName} {props.lastName}</Modal.Title>
            <br />
          </Modal.Header>
          <Modal.Body>
          <Container>
            <Row>
                <Col><h6>Age: {props.age}</h6></Col>
                <Col><h6>Grade: {props.grade}</h6></Col>
            </Row>
            </Container>
            <Container>
                <Container className='mb-2'>
                <h5>Bio:</h5>
                <Card>
                    <Card.Body>
                    <p>{props.bio}</p>
                    </Card.Body>
                </Card>
                </Container>

                <Container className='mb-2'>
                <h5>Goals:</h5>
                <Card>
                    <Card.Body>
                    <p>{props.goals}</p>
                    </Card.Body>
                </Card>
                </Container>

                <Container className='mb-2'>
                <h5>Schools:</h5>
                    <ListGroup>
                        <ListGroup.Item><p>Current School: <span style={{fontSize: '1.25rem'}}>{props.currentSchool}</span></p></ListGroup.Item>
                        <ListGroup.Item><p>Future School: <span style={{fontSize: '1.25rem'}}>{props.futureSchool}</span></p></ListGroup.Item>
                    </ListGroup>
                </Container>

                <Container className='mb-2'>
                <h5>Preventions & Challenges:</h5>
                    <ListGroup>
                        <ListGroup.Item><p>Preventions: <span style={{fontSize: '1.25rem'}}>{props.preventions}</span></p></ListGroup.Item>
                        <ListGroup.Item><p>Challenges: <span style={{fontSize: '1.25rem'}}>{props.challenges}</span></p></ListGroup.Item>
                    </ListGroup>
                </Container>

                <Container>
                <h5>Contact:</h5>
                    <ListGroup>
                        <ListGroup.Item><p>Address: <span style={{fontSize: '1.25rem'}}>{props.address}</span></p></ListGroup.Item>
                        <ListGroup.Item><p>Phone: <span style={{fontSize: '1.25rem'}}>{props.phone}</span></p></ListGroup.Item>
                    </ListGroup>
                    </Container>

            </Container>
            </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}
