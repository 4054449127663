import { Container } from 'react-bootstrap'
import { motion } from 'framer-motion'

export default function Error() {
  return (
    <Container id='page'>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: .5 }}>
        <Container>
        <h2 id='heading'>Sorry but it seems you've reached a error.</h2>
        </Container>
        </motion.div>
    </Container>
  )
}
