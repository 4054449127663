import { Container, Row, Col, Image } from 'react-bootstrap'
import { motion } from "framer-motion";
import './About.css'
import about from '../../images/about.jpeg'

export default function About() {
  return (
    <motion.div 
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ duration: 2, delay: 1 }} 
        >

    <Container fluid id='about-container' className='pt-5 pb-2'>
        <Container className='d-flex align-items-center justify-content-center pb-2 pt-2'>
            <h2 id='heading'>Who We Are</h2>
        </Container>
        <Container fluid>
        <Row>
            <Col sm={12} md={6} className='p-0'>
            <motion.div 
                initial="hidden"
                transition={{ duration: 2, delay: 2 }} 
                whileInView="visible"
                variants={{
                    visible: { opacity: 1, x: 0 },
                    hidden: { opacity: 0, x: -400 }
                  }}
                viewport={{amount: 'some', once: true}}
                >
                <Image src={about} className='img-fluid' id='about-img' rounded />
            </motion.div>
            </Col>
            <Col sm={12} md={6}>
                <Container style={{width: '90%'}}>
                    <p>Altered NWLA is a non-profit agency whose mission is “to provide a network for young women to access information, tools and resources needed to ALTER their path in life and positively impact their community.” Altered started because 2 women had a passion to significantly empower the lives of young women in Shreveport-Bossier City. The name ALTERED was the result of a strategic planning exercise where we affirmed what we wanted our young women to Feel, Be and Live. It is collective summary of our statement of affirmation that “I Am…”</p>
                    <ul className='bodyFont'>
                        <li><h5>Accountable</h5></li>
                        <li><h5>Loved</h5></li>
                        <li><h5>Transformed</h5></li>
                        <li><h5>Empowered</h5></li>
                        <li><h5>Resilient</h5></li>
                        <li><h5>Educated</h5></li>
                        <li><h5>Driven</h5></li>
                    </ul>
                </Container>
            </Col>
        </Row>
        </Container>
    </Container>

    </motion.div>
  )
}
