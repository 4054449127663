// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAWb2v9BFcXSHMxraZ-yS5c4o4L8VUJUMk",
  authDomain: "alterednwla-react-app.firebaseapp.com",
  projectId: "alterednwla-react-app",
  storageBucket: "alterednwla-react-app.appspot.com",
  messagingSenderId: "966077270159",
  appId: "1:966077270159:web:1548cdda088fcc588f48fa",
  measurementId: "G-R5926MHTDK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
const analytics = getAnalytics(app);

const provider = new GoogleAuthProvider()

export const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
    .then((result) => {
        const name = result.user.displayName;
        const email = result.user.email;
        const profilePic = result.user.photoURL;

        console.log(result);

        localStorage.setItem("name", name)
        localStorage.setItem("email", email)
        localStorage.setItem("image", profilePic)
    }).catch((error) => {
        console.log(error)
    })
}