import { Container } from 'react-bootstrap'
import SignUpForm from '../components/Membership/SignUpForm'
import Hero2 from '../components/Hero/Hero2'
import { motion } from 'framer-motion'

export default function Membership() {
  return (
    <Container fluid className='p-0' id='page'>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: .5 }}>
      <Container className='pt-3 pb-3'>
        <h2 className='d-flex align-items-center justify-content-center' id='heading'>Join Altered</h2>
        <p className='d-flex align-items-center justify-content-center'>Complete the membership application form below to join Altered NWLA!</p>
        </Container>
      <Container style={{maxWidth: '50rem'}}>
      <Hero2 />
      <SignUpForm />
      </Container>
      </motion.div>
    </Container>
  )
}
