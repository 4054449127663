import { useState, useEffect } from 'react';
import {Nav, Container, Row, Col, Navbar, Stack, Button, Offcanvas } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { auth } from '../Firebase';
import { motion } from 'framer-motion';
import BlogUploadForm from '../components/Blog/BlogUploadForm';
import GalleryUploadForm from '../components/Gallery/GalleryUploadForm';
import SponsorUploadForm from '../components/Sponsors/SponsorUploadForm';
import EventImageForm from '../components/EventForm/EventImageForm';
import AmbassadorUploadForm from '../components/Founders/AmbassadorUploadForm';
import Inspiration from '../components/Inspiration/Inspiration';
import DashboardApplicationList from '../components/Membership/DashboardApplicationList'
import { BiLeftArrow } from 'react-icons/bi'
import { GoSignOut } from 'react-icons/go'


export default function Dashboard() {
    const {user, changeAccess, access} = UserAuth()
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [show, setShow] = useState(false);

    const {isGoogleUser, signout ,googleLogout} = UserAuth()

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate()

    const navData = [
        {
            'name': 'BlogUploadForm',
            'title': 'Blog Upload Form',
            'desc': 'Use this form to upload a new blog!'
        },
        {
            'name': 'GalleryUploadForm',
            'title': 'Gallery Upload Form',
            'desc': 'Use this form to upload a new image to your gallery!'
        },
        {
            'name': 'EventImageForm',
            'title': 'Event Upload Form',
            'desc': 'Use this form to upload a new event!'
        },
        {
            'name': 'SponsorUploadForm',
            'title': 'Sponsor Upload Form',
            'desc': 'Use this form to upload a new sponsor!'
        },
        {
            'name': 'AmbassadorUploadForm',
            'title': 'Ambassador Upload Form',
            'desc': 'Use this form to upload a new ambassador!'
        },
        {
            'name': 'DashboardApplicationList',
            'title': 'Application List',
            'desc': 'View all of your applications to your program!'
        },
    ]

    const renderedComponent = (componentName) => {
        switch (componentName) {
          case 'BlogUploadForm':
            return <BlogUploadForm />;
          case 'GalleryUploadForm':
            return <GalleryUploadForm />;
          case 'EventImageForm':
            return <EventImageForm />;
        case 'SponsorUploadForm':
            return <SponsorUploadForm />;
        case 'AmbassadorUploadForm':
            return <AmbassadorUploadForm />;
        case 'DashboardApplicationList':
            return <DashboardApplicationList />;
          default:
            return null;
        }
      };

      const handleComponentChange = (componentName) => {
        setSelectedComponent(componentName);
      };

      const logout = async () => {
        try {
            await signout(auth)
            navigate('/') 
        } catch (error) {
            alert('There has been a error. Refresh the page and try again.')
            console.log(error)
        }
        }
  
      const handleGoogleLogout = async () => {
          try {
            await googleLogout()
            navigate('/')
          } catch (error) {
            alert('There has been a error. Refresh the page and try again.')
            console.log(error)
          }
        }

    useEffect(() => {
        if(!user){
            navigate('/signin')
        }
        if(!auth){
            navigate('/signin')
        }
    }, [])

  return (
    <Container id='page' fluid>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: .5 }}>
                <Container className='pt-4 pb-4'>
                    <h2 id='sub-heading'>Admin Dashboard</h2>
                    <h5>Welcome Back!</h5>
                </Container>
                <Container fluid className='p-0'>
                    <Inspiration />
                    <Row style={{minHeight: '50vh'}}>
                        <Col xs={1} className="h-100">
                            <Container className='pt-4 pb-4 h-100 justify-content-center d-flex' style={{backgroundColor: 'pink', borderRadius: '.375rem', minHeight: '20rem', maxWidth: '3.5rem'}}>
                                <Button style={{backgroundColor: 'transparent', color: 'black', border: 'none'}} className="w-auto" onClick={handleShow} >
                                    <BiLeftArrow />
                                </Button>

                                <Offcanvas show={show} onHide={handleClose}>
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title>User Menu</Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Container
                                        className='pt-4 pb-4 h-100 w-100 d-flex flex-column'
                                        style={{ backgroundColor: 'pink', borderRadius: '.375rem' }}
                                    >
                                        <Nav defaultActiveKey="/home" className="flex-column" style={{backgroundColor: 'transparent'}}>
                                                <Stack>
                                                {navData.map((component) => {
                                                    return(
                                                        <Nav.Link onClick={() => {
                                                            setSelectedComponent(`${component.name}`)
                                                            handleClose()
                                                        }}>{component.title}</Nav.Link>
                                                    )
                                                })}
                                                </Stack>
                                        </Nav>
                                        <Container className="mt-auto">
                                        {isGoogleUser ? (
                                            <>
                                            <Button className='w-100' variant="danger" onClick={handleGoogleLogout}><GoSignOut />Logout</Button>
                                            </>
                                        ) : (
                                            <>
                                            <Button className='w-100' variant="danger" onClick={logout}><GoSignOut />Logout</Button>
                                            </>
                                        )}
                                        </Container>
                                    </Container>
                                    </Offcanvas.Body>
                                </Offcanvas>
                            </Container>
                        </Col>
                        <Col xs={11}>
                            <Container className='pt-4 pb-4' fluid style={{paddingLeft: '0px', paddingRight: '0px'}}>
                                {selectedComponent && renderedComponent(selectedComponent)}
                            </Container>
                        </Col>
                    </Row>
                </Container>
        </motion.div>
    </Container>
  )
}
