import { Container, Button, Card, Alert, Form } from 'react-bootstrap'
import { UserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react';
import { GoSignOut } from 'react-icons/go'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export default function SignIn() {
  const {user, signin, googleSignIn} = UserAuth()

  const emailRef = useRef()
  const passwordRef = useRef()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

    // normal signin
  const login = async (e) => {
    e.preventDefault()

    // register try
    try {
        setError('')
        setLoading(true)
        await signin(emailRef.current.value, passwordRef.current.value)
        navigate('/dashboard')
    } catch (error) {
        setError('Failed to log in.')
        console.log(error)
    }

    setLoading(false)
}

  // google signin
  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn()
      navigate('/dashboard')
      console.log(user)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(user) {
      navigate('/dashboard')
    }
  }, [user])

  return (
    <Container id='page'>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: .5 }}>
        <Card className='mb-4' align='center' style={{ maxWidth: '50rem', margin: '0 auto' }}>
          <Card.Body>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form className="p-2" onSubmit={login}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control type="email" placeholder="name@example.com" ref={emailRef} />
                  </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                  <Form.Label>Enter password</Form.Label>
                  <Form.Control type="password" placeholder="Enter your account password" ref={passwordRef} />
              </Form.Group>
              <Button disabled={loading} className='w-100' type='submit'>Login</Button>
              <Link to='/reset-password'>
                <p style={{fontSize: '15px'}} className='w-100 mt-1 mb-1'>Recover Password</p>
              </Link>
              </Form>
          </Card.Body>
        </Card>
        <div style={{maxWidth: '50rem', background: 'black', height: '0.5px', margin: '10px auto'}}></div>
          <Container>
            <h2 className='d-flex align-items-center justify-content-center' id='heading'>Sign In</h2>
            <p className='d-flex align-items-center justify-content-center'>Sign in with Google to continue.</p>
          </Container>
          <Container  className='d-flex align-items-center justify-content-center'>
          <Button onClick={handleGoogleSignIn}><GoSignOut />Sign In</Button>
          </Container>
      </motion.div>
    </Container>
  )
}
