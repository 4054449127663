import { Card, Container, Button } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion';
import PayPalButton from '../Payment/PayPalButton'

export default function SponsorAd() {
  return (
    <Container>
            <Container>
                <Card className='mt-3 mb-3 p-2'> 
                    <Card.Body>
                <h4>Diamond Sponsor</h4>
                <ul>
                    <li>
                        <h6>Recognized in print, promotions, press releases and social media</h6>
                    </li>
                    <li>
                        <h6>Receive display table at all annual events</h6>
                    </li>
                    <li>
                        <h6>Your logo featured on tent</h6>
                    </li>
                    <li>
                        <h6>Receive table for 8 with logo to the annual Break the Glass event</h6>
                    </li>
                    <li>
                        <h6>Receive 4 invitations to graduation</h6>
                    </li>
                    <li>
                        <h6>Recognition on website, events and graduation</h6>
                    </li>
                    <li>
                        <h6>Your logo on one mentee scholarship check to present at graduation</h6>
                    </li>
                    <li>
                        <h6>Company representative invited to be a guest speaker</h6>
                    </li>
                    <li>
                        <h6>Your logo will be featured on classroom binders, programs, flyers, posters and t-shirts</h6>
                    </li>
                    <li>
                        <h6>Receive 5 Altered NWLA t-shirts and window decal.</h6>
                    </li>
                </ul>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Become A Diamond Sponsor</Accordion.Header>
                        <Accordion.Body>
                            <p>The sponsorship fee for this level is $5000.00.</p>
                            <Container style={{maxWidth: '50%'}}>
                                <PayPalButton price={5000.00} />
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                    </Accordion>
                </Card.Body>
                <Card.Footer className=''><h6>You are a partner, your sponsorship will help pay for facilities, utilities, furniture and classroom supplies needed to operate our program.</h6></Card.Footer>
                </Card>

                <Card className='mt-3 mb-3 p-2'> 
                    <Card.Body>
                <h4>Platinum Sponsor</h4>
                <ul>
                    <li>
                        <h6>Recognized in print, promotions, press releases and social media</h6>
                    </li>
                    <li>
                        <h6>Receive display table at all annual events</h6>
                    </li>
                    <li>
                        <h6>Receive table for 8 with logo to the annual Break the Glass event</h6>
                    </li>
                    <li>
                        <h6>Receive 2 invitations to graduation</h6>
                    </li>
                    <li>
                        <h6>Recognition on website and graduation</h6>
                    </li>
                    <li>
                        <h6>Recognition at graduation</h6>
                    </li>
                    <li>
                        <h6>Company representative invited to be a guest speaker</h6>
                    </li>
                    <li>
                        <h6>Company representative invited to present check at graduation</h6>
                    </li>
                    <li>
                        <h6>Company logo on one mentee scholarship check</h6>
                    </li>
                    <li>
                        <h6>Your logo will be featured on classroom binders</h6>
                    </li>
                    <li>
                        <h6>Receive 5 Altered NWLA t-shirts</h6>
                    </li>
                </ul>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Become A Platinum Sponsor</Accordion.Header>
                        <Accordion.Body>
                            <p>The sponsorship fee for this level is $2500.00.</p>
                            <Container style={{maxWidth: '50%'}}>
                                <PayPalButton price={2500.00} />
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                    </Accordion>
                </Card.Body>
                <Card.Footer className=''><h6>Your sponsorship will cover the expenses of one mentee to participate in the ALTERED program, including the $1000 scholarship she will receive.</h6></Card.Footer>
                </Card>

                <Card className='mt-3 mb-3 p-2'> 
                    <Card.Body>
                <h4>Gold Sponsor</h4>
                <ul>
                    <li>
                        <h6>Recognized on website and social media</h6>
                    </li>
                    <li>
                        <h6>Receive 2 invitations to graduation</h6>
                    </li>
                    <li>
                        <h6>Logo in classroom binder</h6>
                    </li>
                    <li>
                        <h6>Recognition at the annual break the glass event</h6>
                    </li>
                    <li>
                        <h6>Recognition at graduation</h6>
                    </li>
                    <li>
                        <h6> Logo on one mentee scholarship check</h6>
                    </li>
                    <li>
                        <h6>Company representative invited to present check to mentee at graduation</h6>
                    </li>
                    <li>
                        <h6>Company representative invited to be a guest speaker</h6>
                    </li>
                </ul>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Become A Gold Sponsor</Accordion.Header>
                        <Accordion.Body>
                            <p>The sponsorship fee for this level is $1000.00.</p>
                            <Container style={{maxWidth: '50%'}}>
                                <PayPalButton price={1000.00} />
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                    </Accordion>
                </Card.Body>
                <Card.Footer className=''><h6>Your sponsorship will provide a $1000 scholarship to a mentee.</h6></Card.Footer>
                </Card>

                <Card className='mt-3 mb-3 p-2'> 
                    <Card.Body>
                <h4>Silver Sponsor</h4>
                <ul>
                    <li>
                        <h6>Logo in classroom binder</h6>
                    </li>
                    <li>
                        <h6>Recognition on website and social media</h6>
                    </li>
                    <li>
                        <h6>Recognition on social media for event/outing sponsored</h6>
                    </li>
                </ul>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Become A Silver Sponsor</Accordion.Header>
                        <Accordion.Body>
                            <p>The sponsorship fee for this level is $500.00.</p>
                            <Container style={{maxWidth: '50%'}}>
                                <PayPalButton price={500.00} />
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                    </Accordion>
                </Card.Body>
                <Card.Footer className=''><h6>Your sponsorship will pay for a relationship building outing/field trip for mentors & mentees to connect and bond outside of classroom.</h6></Card.Footer>
                </Card>

                <Card className='mt-3 mb-3 p-2'> 
                    <Card.Body>
                <h4>Bronze Sponsor</h4>
                <ul>
                    <li>
                        <h6>Name on Website</h6>
                    </li>
                    <li>
                        <h6>Recognition in Classroom for Lunch/Breakfast sponsored</h6>
                    </li>
                </ul>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Become A Bronze Sponsor</Accordion.Header>
                        <Accordion.Body>
                            <p>The sponsorship fee for this level is $250.00.</p>
                            <Container style={{maxWidth: '50%'}}>
                                <PayPalButton price={250.00} />
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                    </Accordion>
                </Card.Body>
                <Card.Footer className=''><h6>Your sponsorship will pay for breakfast/lunch for one month of classes.</h6></Card.Footer>
                </Card>

            </Container>
            <Container>
                <h2>Every Donation Helps Our Organization Make A Difference!</h2>
                <p>A $100 donation pays for a financial university class for one mentee. A $25 donation pays for one classroom binder</p>
            </Container>
    </Container>
  )
}
