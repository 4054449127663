import { useState, useEffect } from 'react'
import { Container, Form, Row, Col, Image, Button, Card } from 'react-bootstrap'
import { storage } from '../../Firebase'
import { ref, uploadBytes, listAll, getDownloadURL, deleteObject } from 'firebase/storage'
import { useNavigate } from 'react-router-dom'
import { motion, useScroll } from 'framer-motion'
import { FaTrash } from 'react-icons/fa'
import uuid from 'react-uuid';

export default function SponsorUploadForm() {
    const [imageUpload, setImageUpload] = useState(null)
    const [sponsorList, setSponsorList] = useState([])
    const sponsorsListRef = ref(storage, "sponsors/")

    const navigate = useNavigate()
    const { scrollYProgress } = useScroll()

    useEffect(() => {
        listAll(sponsorsListRef)
        .then((res) =>{
            res.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setSponsorList((prev) => [...prev, { url, item }])
                })
            })
        })
    }, [!sponsorList])

    const uploadImage = async () => {
        if(imageUpload == null) return;
        try {
            const imageRef = ref(storage, `sponsors/${imageUpload.name + uuid()}`)
            await uploadBytes(imageRef, imageUpload)
            alert("Congratulations! You've added a new sponsor!")
            navigate('/')
        } catch (error) {
            console.log(error)
            alert("Sorry. There's been an erorr with your upload. Refresh the page or logout and try again.")
        }
    }

    const deleteImage = async (item) => {
        try {
            await deleteObject(item);
            setSponsorList((prev) =>
              prev.filter((image) => image.item.name !== item.name)
            );
            alert('Success. The image was deleted successfully.');
          } catch (error) {
            console.log(error);
            alert('Sorry. There seems to be a failure.');
          }
    }

    const SponsorList = () => {
        if(sponsorList === null) {
            return (
                <p>Loading...</p>
            )
        }
        if(sponsorList.length === 0) {
            return (
                <p>No sponsors...</p>
            )
        }

        return(
            <Row className='mt-2'>
                {sponsorList.map(({ url, item }) => {
                    return (
                        <Col xs={12} md={6} lg={4}>
                            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} viewport={{ once: true}} transition={{ duration: 1, delay: 1 }}>
                                <Container fluid key={uuid()} className="justify-content-left align-items-center d-flex">
                                    <Image src={url} style={{maxHeight: '200px', maxWidth: '200px'}} className='img-fluid' />
                                </Container>
                                <Button className='w-auto mt-2' variant='danger' onClick={() => deleteImage(item)}>
                                        <FaTrash />
                                    </Button>
                            </motion.div>
                        </Col>
                    )
                })}
            </Row>
        )
    }

  return (
    <Container fluid className='p-0'>
        <Container fluid>
            <Card style={{maxWidth: '30rem'}}>
                <Card.Body>
                    <Form style={{maxWidth: '30rem'}}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <h3>Sponsor Upload</h3>
                            <p>Need to add a sponsor to your site? Start here!</p>
                            <Form.Label>Sponsor Image Upload</Form.Label>
                            <Form.Control type="file" placeholder="Upload your image" onChange={(event) => {setImageUpload(event.target.files[0])}} />
                        </Form.Group>
                        <Button onClick={uploadImage} variant="primary">
                            Upload Image
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
        <Container className='mt-4' style={{borderTop: 'solid black 1px'}}>
            <h5>Current Sponsors</h5>
            <SponsorList />
        </Container>
    </Container>
  )
}
