import { Container } from 'react-bootstrap'
import { motion } from 'framer-motion'
import './Hero.css'

export default function Hero() {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: .5 }}>
    <Container id='hero-container2' fluid className='p-0 m-0'>
    </Container>
    </motion.div>
  )
}
