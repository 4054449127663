import { Container, Image } from 'react-bootstrap'
import { motion } from 'framer-motion'
import './Hero.css'
import hero2 from '../../images/hero2.jpg'

export default function Hero() {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: .5 }}>
    <Container fluid className='p-0' style={{width: '100vw'}}>
      <Image src={hero2} className='img-fluid' style={{width: '100%'}} />
    </Container>
    </motion.div>
  )
}
