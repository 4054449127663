import { Container } from 'react-bootstrap'
import Video from '../Blog/Video'
import { motion } from 'framer-motion'
import './Program.css'

export default function Program() {
  return (
    <motion.div 
      initial={{ opacity: 0 }} 
      animate={{ opacity: 1 }} 
      transition={{ duration: 2, delay: 1 }} 
      >

    <Container fluid id='program-container' className='pt-5'>
          <Container className='pt-2 d-flex align-items-center justify-content-center'>
              <h2 id='heading'>Our Program</h2>
          </Container>

          <Container fluid className='p-0'>
          <Container style={{maxWidth: '50rem'}}>
              <p>ALTERED Northwest Louisiana provides mentorship and empowerment programs for young women in the Shreveport­Bossier City area, to develop their leadership abilities, define and achieve personal goals, explore and acquire career and college skills, and more. Our program provides interactive workshops two Saturdays a month, that promote self awareness, self confidence, finding their purpose, goal planning, financial literacy, cultivating sphere of influence, and comfort with public speaking.
                  <br />
                  These young women are paired with a mentor and provided one on one coaching, based on desire and needs. Our program serves a diverse group of young ladies in our community, between the ages of16 and 25. This organization is open to women of all walks of life and our program is free of charge.100% of your tax deductible contribution to ALTERED will directly support our scholarship fund and ensure that these young women in Northwest Louisiana have access to our leadership and mentorship programs.</p>
          </Container>
          <Container>
            <Video />
          </Container>
        </Container>
    </Container>

    </motion.div>
  )
}
