import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { getDocs, collection } from 'firebase/firestore'
import { db } from '../../Firebase'
import './Founders.css'

export default function AmbassadorList() {
    const [ambassadorList, setAmbassadorList] = useState([])

    useEffect(() => {
        const ambassadorCollectionRef = collection(db, "ambassadors");
    
        const fetchData = async () => {
          const data = await getDocs(ambassadorCollectionRef);
          const ambassadors = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
          setAmbassadorList(ambassadors);
        };
    
        fetchData();
      }, []);

    if(ambassadorList === null) {
        return(
            <p>Loading...</p>
        )
    }

  return (
    <Container style={{ maxWidth: '80rem' }} className='pt-4'>
    {ambassadorList.length > 0 ? (
      <>
        <Container style={{ maxWidth: '80rem' }} className='pt-4'>
          <h3 id='heading' align='center'>Ambassadors:</h3>
          <Row className='p-1'>
            {ambassadorList.map((ambassador) => (
              <Col xs={12} md={4} className='p-1' key={ambassador.id}>
                <motion.div
                  initial="hidden"
                  transition={{ duration: 1.5, delay: 1 }}
                  whileInView="visible"
                  variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 400 }
                  }}
                  viewport={{ amount: 'some', once: true }}
                >
                  <Container className='fluid' style={{ width: '100%', height: '400px', backgroundImage: `url(${ambassador.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', borderRadius: '.375rem' }}></Container>
                  <p className="ambassador-name">{ambassador.name}</p>
                </motion.div>
              </Col>
            ))}
          </Row>
        </Container>
      </>
    ) : (
      <p>No ambassadors</p>
    )}
  </Container>
  )
}
