import { useState, useEffect } from 'react'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { db, storage } from '../../Firebase';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button, Image } from 'react-bootstrap'
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';

export default function SignUpForm() {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [age, setAge] = useState(0)
    const [address, setAddress] = useState("")
    const [grade, setGrade] = useState(0)
    const [currentSchool, setCurrentSchool] = useState("")
    const [futureSchool, setFutureSchool] = useState("")
    const [bio, setBio] = useState("")
    const [goals, setGoals] = useState("")
    const [preventions, setPreventions] = useState("")
    const [phone, setPhone] = useState(0)
    const [challenges, setChallenges] = useState("")

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const membershipCollectionRef = collection(db, "membership")

    const timestamp = Date.now();

    // Define your EmailJS service ID, template ID, and user ID
    const SERVICE_ID = 'service_7g1j3ep';
    const TEMPLATE_ID = 'template_566w2f4';
    const USER_ID = '4UhXRUE3oDZaYCjmV';

    const [pdfURL, setPdfURL] = useState('');


    useEffect(() => {
        const getPdfURL = async () => {
          const pdfRef1 = ref(storage, 'forms/Application.pdf'); // Adjust the path to your Firebase Storage PDF
          try {
            const url = await getDownloadURL(pdfRef1);
            setPdfURL(url);
          } catch (error) {
            console.error('Error getting PDF URL:', error);
          }
        };
      
        getPdfURL();
      }, []);


    const sendEmail = async (formData) => {
    const templateParams = {
        to_email: 'jeannette@alterednwla.org', // Replace with the recipient's email address
        user_name: formData.firstName + ' ' + formData.lastName,
        message: `You have a new application submission from ${formData.firstName} ${formData.lastName}`
        // Add other template parameters as needed
    };

    try {
        await emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID);
        console.log('Email sent successfully');
    } catch (error) {
        console.error('Email sending failed:', error);
    }
    };

    const createPost = async () => {
        setLoading(true);
        try {
          // Create a new document with the form data
          await addDoc(membershipCollectionRef, {
            address,
            age,
            email,
            bio,
            challenges,
            current_school_name: currentSchool,
            first_name: firstName,
            future_school_name: futureSchool,
            goals,
            grade,
            last_name: lastName,
            phone,
            preventions,
            submittedAt: timestamp// Firestore will automatically generate a server timestamp for submittedAt
          });
      
          // Send the email
          await sendEmail({
            firstName,
            lastName,
            // Add other form data as needed
          });
      
          alert('Success');
          handleReset();
          navigate('/');
        } catch (error) {
          console.log(error);
          alert('Sorry, there seems to be an error. Please try again.');
        } finally {
          setLoading(false);
        }
      };
      

    const handleReset = () => {
        setFirstName("")
        setLastName("")
        setAge(0)
        setAddress("")
        setGrade(0)
        setCurrentSchool("")
        setFutureSchool("")
        setBio("")
        setGoals("")
        setPreventions("")
        setPhone(0)
        setChallenges("")
    }

    const [imageURL, setImageURL] = useState('');
    const [imageURL2, setImageURL2] = useState('');

    useEffect(() => {
        const getImageURL = async () => {
          const imageRef = ref(storage, 'forms/Application-1.png'); // Adjust the path to your Firebase Storage image
          const imageRef2 = ref(storage, 'forms/Application-2.png'); // Adjust the path to your Firebase Storage image
          try {
            const url = await getDownloadURL(imageRef);
            const url2 = await getDownloadURL(imageRef2);
            setImageURL(url);
            setImageURL2(url2)
          } catch (error) {
            console.error('Error getting image URL:', error);
          }
        };
    
        getImageURL();
        }, []); // Empty dependency array to run this effect only once

    const handleDownloadPdf = () => {
        window.open(pdfURL, '_blank');
      };

  return (
    <Container fluid className='mt-5 p-0 '>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: .5 }}>
        <p align='center'>Send over your complete application to jeannette@alterednwla.org.</p>
        <Image src={imageURL} style={{width: '100%', height: 'auto'}} alt="alterednwla-form" />
        <Image src={imageURL2} style={{width: '100%', height: 'auto'}} alt="alterednwla-form" />
        <Container align='center' className='d-block'>
            <Button className='m-1' variant='primary' onClick={handleDownloadPdf}>
            Download Application
            </Button>

        </Container>

        {/*}
        <Form>
            <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="name" placeholder="Enter your first name" onChange={(e) => {setFirstName(e.target.value)}} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="name" placeholder="Enter your last name" onChange={(e) => {setLastName(e.target.value)}} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicTime">
                <Form.Label>Age</Form.Label>
                <Form.Control type="number" placeholder="Enter your age" onChange={(e) => {setAge(e.target.value)}} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDate">
                <Form.Label>Address</Form.Label>
                <Form.Control type="address" placeholder="Enter your address" onChange={(e) => {setAddress(e.target.value)}} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDate">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type="number" placeholder="Enter your phone number" onChange={(e) => {setPhone(e.target.value)}} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" placeholder="Enter your email address" onChange={(e) => {setEmail(e.target.value)}} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDescription">
                <Form.Label>Current School</Form.Label>
                <Form.Control type="text" placeholder="What school do you currently attend?" onChange={(e) => {setCurrentSchool(e.target.value)}} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDescription">
                <Form.Label>Future School</Form.Label>
                <Form.Control as='textarea' style={{height: '100px'}} type="text" placeholder="What school do you want to attend in the future? Why?" onChange={(e) => {setFutureSchool(e.target.value)}} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDescription">
                <Form.Label>Grade</Form.Label>
                <Form.Control type="text" placeholder="Enter your grade. (ie: 'senior' or 'junior')" onChange={(e) => {setGrade(e.target.value)}} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDescription">
                <Form.Label>Bio</Form.Label>
                <Form.Control as='textarea' style={{height: '100px'}} type="text" placeholder="Tell us about yourself" onChange={(e) => {setBio(e.target.value)}} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDescription">
                <Form.Label>Goals</Form.Label>
                <Form.Control as='textarea' style={{height: '100px'}} type="text" placeholder="Tell us about your future goals" onChange={(e) => {setGoals(e.target.value)}} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicDescription">
                <Form.Label>Preventions</Form.Label>
                <Form.Control as='textarea' style={{height: '100px'}} type="text" placeholder="Are there any handicaps or issues that prevent you?" onChange={(e) => {setPreventions(e.target.value)}} />
            </Form.Group>

            <Button variant="primary" onClick={createPost} disabled={loading}>
                {loading ? 'Submitting' : 'Submit'}
            </Button>
        </Form>
        {*/}
        </motion.div>
    </Container>
  )
}