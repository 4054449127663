import { motion } from 'framer-motion'
import { Col, Container, Row, Button, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function FooterAd() {
  return (
    <motion.div 
            initial="hidden"
            whileInView="visible" 
            viewport={{ once: true }} 
            transition={{duration: 2, delay: 1}}
             variants={{
                visible: { opacity: 1, x: 0 },
                hidden: { opacity: 0, x: 400 }
            }}>
    <Container style={{minHeight: '20rem', maxWidth: '70rem'}} fluid className='p-0 d-flex align-items-center justify-content-center'>
        <Row className='d-flex align-items-center justify-content-center' style={{width: '99%'}}>
            <Col sm={6}>
                <Card className='d-flex align-items-center justify-content-center' style={{minHeight: '15rem'}}>
                    <Card.Body>
                    <Card.Header><h3>Get The Application</h3></Card.Header>
                    <p>Click Here to Fill out the Application & Join the Program!</p>
                    <Link to='/application'><Button variant='outline-primary'>Click Here</Button></Link>
                    </Card.Body>
                </Card>
            </Col>
            <Col sm={6}>
                <Card>
                    <Card.Body style={{minHeight: '15rem'}}>
                    <Card.Header><h3>Become a Sponsor!</h3></Card.Header>
                    <p>Click Here to Become a Sponsor for Altered NWLA!</p>
                    <Link to='/donations'><Button variant='outline-primary'>Click Here</Button></Link>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
    </motion.div>
  )
}
