import { Container } from 'react-bootstrap'
import Hero from '../components/Hero/Hero3'
import QuickPayButton from '../components/Payment/QuickPayButton'
import SponsorAd from '../components/Sponsors/SponsorAd'
import { motion } from 'framer-motion'
import DonationAd from '../components/Donations/DonationAd'

export default function Donations() {
  return (
    <Container id='page' fluid className='p-0'>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: .5 }}>
      <Container className='pt-3 pb-3'>
        <h2 className='d-flex align-items-center justify-content-center' id='heading'>Donations</h2>
        <p className='d-flex align-items-center justify-content-center'>Support AlertedNWLA by becoming a sponsor, subscription, or one time payment.</p>
        </Container>
        <Container className='pt-2 pb-2' style={{maxWidth: '50rem'}}>
        <Hero />
          <h3 className='d-flex align-items-center justify-content-center pt-2 pb-2'>Become a Sponsor!</h3>
            <SponsorAd />
          <h3 className='d-flex align-items-center justify-content-center pt-2 pb-2'>Make a One Time Donation</h3>
          <Container style={{maxWidth: '35rem'}}>
            <DonationAd />
          </Container>
        </Container>
        </motion.div>
    </Container>
  )
}
