import { useState, useEffect } from 'react'
import { Container, Form, Row, Col, Card, Button } from 'react-bootstrap'
import { storage } from '../../Firebase'
import { ref, uploadBytes, listAll, getDownloadURL, deleteObject } from 'firebase/storage'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { FaTrash } from "react-icons/fa";
import uuid from 'react-uuid';

export default function GalleryUploadForm() {
    const [imageUpload, setImageUpload] = useState(null)
    const [galleryList, setGalleryList] = useState([]);
    const galleryListRef = ref(storage, "images/")
    const navigate = useNavigate()

    useEffect(() => {
        listAll(galleryListRef).then((res) => {
          res.items.forEach((item) => {
            getDownloadURL(item).then((url) => {
              setGalleryList((prev) => [...prev, { url, item }]);
            });
          });
        });
      }, [!galleryList]);

    const uploadImage = async () => {
        if(imageUpload == null) return;
        try {
            const imageRef = ref(storage, `images/${imageUpload.name + uuid()}`)
            await uploadBytes(imageRef, imageUpload)
            alert('Success. Your image was uploaded successfully.')
            navigate('/gallery')
        } catch (error) {
            console.log(error)
            alert('Sorry. There seems to be a failure.')
        }
    }

    const deleteImage = async (item) => {
        try {
            await deleteObject(item);
            setGalleryList((prev) =>
              prev.filter((image) => image.item.name !== item.name)
            );
            alert('Success. The image was deleted successfully.');
          } catch (error) {
            console.log(error);
            alert('Sorry. There seems to be a failure.');
          }
    }

    const GalleryList = () => {
        if(galleryList === null) {
            return(
                <p>Loading</p>
            )
        }

        if(galleryList.length === 0) {
            return(
                <p>No images...</p>
            )
        }

        return(
            <Row className='mt-2'>
                {galleryList.map(({ url, item }) => {
                    if(url === null){
                        return(
                            <p>Loading...</p>
                        )
                    }
                    return (
                        <Col xs={12} md={6} lg={4}>
                            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: .5 }}>
                            <Container fluid className='p-2' key={uuid()}>
                                <Card style={{width: '300px', height: '300px', backgroundImage: `url(${url})`, backgroundPosition: 'center', backgroundSize: 'cover'}}>
                                </Card>
                                <Button className='w-auto mt-2' variant='danger' onClick={() => deleteImage(item)}>
                                    <FaTrash />
                                </Button>
                            </Container>
                            </motion.div>
                        </Col>
                    )
            })}
            </Row>
        )
    }

  return (
    <Container fluid className='p-0'>
        <Container fluid>
            <Card style={{maxWidth: '30rem'}}>
                <Card.Body>
                    <Form style={{maxWidth: '30rem'}}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <h3>Gallery Image File Upload</h3>
                            <p>Got a new gallery image to show off? Upload it here!</p>
                            <Form.Label>Gallery File Upload</Form.Label>
                            <Form.Control type="file" placeholder="Upload your image" onChange={(event) => {setImageUpload(event.target.files[0])}} />
                        </Form.Group>
                        <Button onClick={uploadImage} variant="primary">
                            Upload Image
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
        <Container className='p-0 mt-4' style={{borderTop: 'solid black 1px'}}>
            <h5>Current Gallery Images</h5>
            <GalleryList />
        </Container>
    </Container>
  )
}
