import {Container} from 'react-bootstrap'
import './Blog.css'

export default function Video() {
  return (
    <Container className='d-flex align-items-center justify-content-center' id='video-container'>
        <iframe src={'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Falterednwla%2Fvideos%2F514422669296833%2F&show_text=false&width=560&t=0'} width={'560'} height={'314'} style={{border: 'none', overflow: 'hidden', borderRadius: '.375rem'}} scrolling="no" frameBorder={0} allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </Container>
  )
}
