import { UserAuth } from '../../context/AuthContext';
import { auth } from '../../Firebase';
import './Navigation.css'
import { useNavigate } from 'react-router-dom';
import { Dropdown, Button } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import { GoSignOut } from 'react-icons/go'

export default function UserNav() {
    const {isGoogleUser, signout ,googleLogout} = UserAuth()
    const navigate = useNavigate()

    const logout = async () => {
      try {
          await signout(auth)
          navigate('/') 
      } catch (error) {
          alert('There has been a error. Refresh the page and try again.')
          console.log(error)
      }
  }

    const handleGoogleLogout = async () => {
        try {
          await googleLogout()
          navigate('/')
        } catch (error) {
            alert('There has been a error. Refresh the page and try again.')
            console.log(error)
        }
      }

  return (
    <Dropdown>
      <Dropdown.Toggle variant="primary" id="dropdown-basic">
        Admin
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item className='d-flex align-items-center justify-content-center'>
          <NavLink to='/dashboard' style={{color: 'black'}} className='nav-link'>Dashboard</NavLink>
        </Dropdown.Item>
        <Dropdown.Item className='d-flex align-items-center justify-content-center'>
          {isGoogleUser ?
          <>
          <Button variant="danger" onClick={handleGoogleLogout}><GoSignOut />Logout</Button></>
          :
          <>
          <Button variant="danger" onClick={logout}><GoSignOut />Logout</Button></>
          }
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
