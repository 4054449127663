import { Container, Row, Col, Image, Card } from 'react-bootstrap'
import { motion } from 'framer-motion'
import ContactForm from './ContactForm'
import './Contact.css'
import support from '../../images/support.jpeg'
import hero from '../../images/hero.jpeg'
import OnePayButton from '../Payment/OnePayButton'
import AccordionForm from '../Payment/AccordionForm'

export default function Contact() {
  return (
    <motion.div
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ duration: 2, delay: 1 }} 
        >

    <Container fluid id='contact-container' className='pt-5 pb-5'>
        <Container className='d-flex align-items-center justify-content-center pt-2 pb-2'>
            <h2 id='heading'>How can you help?</h2>
        </Container>
        <Container>
        <motion.div 
                initial="hidden"
                transition={{ duration: 2, delay: 1 }} 
                whileInView="visible"
                variants={{
                    visible: { opacity: 1, x: 0 },
                    hidden: { opacity: 0, x: -200 }
                  }}
                viewport={{amount: 'some', once: true}}
                >
            <Row>
                    <Col  sm={12} lg={6}>
                    <Container fluid className='p-1 m-0 d-flex align-items-center justify-content-center'>
                        <Image id='contact-img' rounded src={support} className='img-fluid p-0' />
                    </Container>
                    </Col>
                    <Col sm={12} lg={6}>
                    <Container className='p-1 m-0' style={{maxWidth: '40rem'}}>
                        <ul className='bodyFont'>
                           <li>
                            <h5>Identify and nominate a young lady who may not have the resources, network, support or opportunity to participate in a program like ours and whom you feel will benefit from Altered.</h5>
                           </li>
                           <li>
                           <h5>Become a Sponsor by investing in our efforts.</h5>
                           </li>
                           <li>
                           <h5>Become a Donor and give to our online fundraising campaign.</h5>
                           </li>
                           <li>
                           <h5>Donate in-kind items to assist us with operating the program (food, clothes, laptops, tablets, printers, paper, gift cards, meeting space, hotel/lodging gifts, etc)</h5>
                           </li>
                           <li>
                           <h5>Follow us on Facebook, Instagram, Twitter, YouTube.</h5>
                            </li> 
                           <li>
                           <h5>Become a Volunteer/Mentor/Trainer/Speaker at a monthly meeting or special event.</h5>
                           </li>
                           <li>
                           <h5>Give us your feedback, support and prayers.</h5>
                           </li>
                        </ul>
                    </Container>
                    </Col>
                </Row>
                </motion.div>
                <motion.div 
                initial="hidden"
                transition={{ duration: 2, delay: 1 }} 
                whileInView="visible"
                variants={{
                    visible: { opacity: 1, x: 0 },
                    hidden: { opacity: 0, x: 200 }
                  }}
                viewport={{amount: 'some', once: true}}
                >
                <Row>
                    <Col sm={12} md={5}>
                        <Container fluid>
                            <Card className='p-2'>
                                <Card.Header><h3>Donation</h3></Card.Header>
                                <Card.Subtitle className='mt-2 mb-2'>Give to ensure girls have the experiences and opportunities to thrive.</Card.Subtitle>
                                <p className='mb-0 pb-0'>I want to donate:</p>
                                <Container className='p-0' fluid>
                                <Card.Body className='d-flex align-items-center justify-content-center'>
                                    <AccordionForm />
                                </Card.Body>
                                </Container>
                            </Card>
                        </Container>
                    </Col>
                    <Col sm={12} md={7}>
                        <Container fluid className='p-1 m-0 d-flex align-items-center justify-content-center'>
                        <Image id='contact-img' rounded src={hero} className='img-fluid p-0' />
                        </Container>
                    </Col>
                </Row>
                </motion.div>
        </Container>
        <Container>
            <ContactForm />
        </Container>
    </Container>

    </motion.div>
  )
}
