import { useEffect, useState } from 'react'
import { Container, Button, Card } from 'react-bootstrap'
import BlogCard from './BlogCard'
import { collection, deleteDoc, getDocs, doc } from 'firebase/firestore'
import { db } from '../../Firebase'
import { UserAuth } from '../../context/AuthContext'
import { motion } from 'framer-motion'

export default function BlogList() {
    const [blogList, setBlogList] = useState(null)
    const postsCollectionRef = collection(db, "posts")
    const {user} = UserAuth()

    useEffect(() => {
        const getPosts = async () => {
            const data = await getDocs(postsCollectionRef)
            setBlogList(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
            console.log(blogList)
        }
        getPosts()
    }, [!blogList])

    const deletePost = async (id) => {
        try {
            const postDoc = doc(db, "posts", id);
            await deleteDoc(postDoc);
            refreshList()
        } catch (error) {
            console.log(error)
        }
    }

    const refreshList = async () => {
        const data = await getDocs(postsCollectionRef)
        setBlogList(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
        console.log(blogList)
    }

    const formatPostText = (text) => {
        if (!text) return null;
        const paragraphs = text.split('\n');
        return paragraphs.map((paragraph, index) => <p key={index}>{paragraph}</p>);
    };

    if(!blogList) {
        return (
            <h1>Loading</h1>
                )
    }

  return (
    <Container fluid>
      {blogList.map((post) => {
        return (
          <Container key={post.id} fluid className="p-0">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: 0.5 }}>
              <Container fluid>
                <Container fluid className="mt-4 mb-4 p-2 d-flex align-items-center justify-content-center">
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.3 }}>
                    <Card id="blog-card">
                      <Card.Body>
                        <Card.Title className="d-flex align-items-center justify-content-center mt-2 mb-2">{post.title}</Card.Title>
                        <Container id="nav-divider" className="mt-3 mb-3 bg-dark"></Container>
                        <Card.Text>{formatPostText(post.postText)}</Card.Text> {/* Remove fixed height and border */}
                      </Card.Body>
                      <Container className="d-flex">
                        <h6>Author: {post.author}</h6>
                      </Container>
                    </Card>
                  </motion.div>
                </Container>
                {user ? (
                  <>
                    <Container className="d-flex align-items-center justify-content-center">
                      <Button variant="danger" onClick={() => {
                        deletePost(post.id);
                      }}>Delete
                      </Button>
                    </Container>
                  </>
                ) : (
                  <>
                  </>
                )}
              </Container>
            </motion.div>
          </Container>
        );
      })}
    </Container>
  )
}
