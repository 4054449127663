import { Container } from 'react-bootstrap'
import { motion } from 'framer-motion'
import GPSHero from '../components/GPS/GPSHero'

export default function GPS() {
  return (
    <Container id='page'>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: .5 }}>
        <Container className='pb-4 pt-4'>
          <h2 className='d-flex align-items-center justify-content-center' id='heading'>GPS</h2>
          <p className='d-flex align-items-center justify-content-center'>Girls Prepared equals Success. Learn more about our program below and how we help prepare our girls for success!</p>
        </Container>
        </motion.div>
        <Container>
            <GPSHero />
        </Container>
        <Container style={{maxWidth: '50rem'}}>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: .5 }}>
            <p className='d-flex align-items-center justify-content-center'>GPS will help girls set goals and design a plan for their future success. Although, we can’t predict
            the future, it’s never too early to start planning for it.
            GPS provides a real- life simulation, that requires the girls to make real life choices in their lifestyle,
            finances, and budget.
            Each of the girls will be assigned a career, a salary, and a checkbook register.  They are randomly
            assigned a marital status and up to three children.  Girls are then released to where a mock
            community awaits.  The community includes approximately 16 - 20 separate booths manned by
            local businessmen and women who are eager to teach lessons on just how far a dollar can go.  
            The event, hosted by Altered, involves a partnership where the schools and local businesses come
            together for a day.  Community participants will include Bankers, Mortgage Lenders, Real Estate
            Agents, Insurance Agents, Physicians, Dentists, Car Salesmen, Law Enforcement, and several
            other volunteers.  GPS provides an excellent opportunity for young ladies to gain some insight into
            the many responsibilities of adulthood while offering them an opportunity to think about their career
            choices.</p>
        </motion.div>
        </Container>
    </Container>
  )
}
