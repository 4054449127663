import React from 'react'
import { Container } from 'react-bootstrap'
import BlogList from '../components/Blog/BlogList'
import { motion } from 'framer-motion'

export default function Blog() {
  return (
    <Container id='page'>
      <Container>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: .5 }}>
        <Container className='pb-4 pt-4'>
          <h2 className='d-flex align-items-center justify-content-center' id='heading'>Altered NWLA Newsletter</h2>
          <p className='d-flex align-items-center justify-content-center'>Learn more about Altered and our organization.</p>
        </Container>
        </motion.div>
        <Container className='pb-4 pt-4'>
          <BlogList />
        </Container>
      </Container>
    </Container>
  )
}
