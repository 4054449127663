import { useState, useEffect } from 'react'
import { Container, Form, Row, Col, Card, Button, Image } from 'react-bootstrap'
import { storage } from '../../Firebase'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { addDoc, collection, deleteDoc, getDocs, doc } from 'firebase/firestore'
import { db } from '../../Firebase'
import { motion } from 'framer-motion'
import { FaTrash } from "react-icons/fa";
import uuid from 'react-uuid';

export default function AmbassadorUploadForm() {
    const [name, setName] = useState("");
    const [imageUpload, setImageUpload] = useState(null)
    const [ambassadorList, setAmbassadorList] = useState([]);

    const ambassadorCollectionRef = collection(db, "ambassadors");

    const fetchData = async () => {
        const data = await getDocs(ambassadorCollectionRef);
        const ambassadors = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setAmbassadorList(ambassadors);
      };

    useEffect(() => {
        fetchData();
      }, [!ambassadorList]);

      const uploadImage = async () => {
        if (imageUpload === null) return;
        try {
          const imageRef = ref(storage, `ambassadors/${imageUpload.name + uuid()}`);
          await uploadBytes(imageRef, imageUpload);
    
          // Get the URL of the uploaded image
          const imageUrl = await getDownloadURL(imageRef);
    
          // Save the ambassador data (image URL and name) to the database
          // Assuming you have a Firestore collection called 'ambassadors'
          await addDoc(collection(db, "ambassadors"), {
            name,
            imageUrl,
          });
    
          alert('Success. Your image and name were uploaded successfully.');

          fetchData();
          setName("")
        } catch (error) {
          console.log(error);
          alert('Sorry. There seems to be a failure.');
        }
      };
    

      const deleteDocument = async (id) => {
        try {
          // Create a reference to the specific ambassador document using the ID
          const ambassadorDocRef = doc(db, "ambassadors", id);
          
          // Delete the document
          await deleteDoc(ambassadorDocRef);
      
          // Update the ambassadorList state to remove the deleted ambassador
          setAmbassadorList((prev) =>
            prev.filter((ambassador) => ambassador.id !== id)
          );
      
          alert('Success. The document was deleted successfully.');
        } catch (error) {
          console.log(error);
          alert('Sorry. There seems to be a failure.');
        }
      };

  return (
    <Container fluid className='p-0'>
        <Container fluid>
            <Card style={{ maxWidth: '30rem' }}>
            <Card.Body>
                <Form style={{ maxWidth: '30rem' }}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <h3>Ambassador Image File Upload</h3>
                    <p>New ambassador to your team? Upload their picture here!</p>
                    <Form.Label>Ambassador Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter ambassador name" onChange={(event) => { setName(event.target.value) }} />

                    <Form.Label>Ambassador File Upload</Form.Label>
                    <Form.Control type="file" placeholder="Upload your image" onChange={(event) => { setImageUpload(event.target.files[0]) }} />
                </Form.Group>
                <Button onClick={uploadImage} variant="primary">
                    Upload Image
                </Button>
                </Form>
            </Card.Body>
            </Card>
        </Container>
        <Container className='p-0 mt-4' style={{borderTop: 'solid black 1px'}}>
            <Container style={{ maxWidth: '80rem' }} className='pt-4'>
                {ambassadorList.length > 0 ? (
                <>
                    <Container style={{ maxWidth: '80rem' }} className='pt-4' align='left'>
                    <h5 align='left'>Current Ambassadors:</h5>
                    <Row className='p-1'>
                        {ambassadorList.map((ambassador) => (
                        <Col xs={12} md={4} className='p-1' key={ambassador.id}>
                            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: .5 }}>
                            <Image src={ambassador.imageUrl} className='img-fluid' style={{ height: '15rem' }} rounded />
                            <p className="ambassador-name">{ambassador.name}</p>
                            <Button className='w-auto mt-2' variant='danger' onClick={() => deleteDocument(ambassador.id)}>
                                <FaTrash />
                            </Button>
                            </motion.div>
                        </Col>
                        ))}
                    </Row>
                    </Container>
                </>
                ) : (
                <p>No ambassadors</p>
                )}
            </Container>
        </Container>
    </Container>
  )
}
