import { Container } from 'react-bootstrap'
import { motion } from 'framer-motion'
import './Footer.css'

export default function Footer() {
  return (
    <motion.div
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ duration: 2, delay: 1 }}
        >
    <Container fluid id='footer' className='d-flex align-items-center justify-content-center bodyFont'>
        <Container id='footer-container' className='d-flex align-items-center justify-content-center'>
            <motion.div
                initial={{ opacity: 0 }} 
                animate={{ opacity: 1 }} 
                transition={{ duration: 2, delay: 1.5 }}>
                <h6>© AlteredNWLA 2017-2022. All Rights Reserved.</h6>
            </motion.div>
        </Container>
    </Container>
    </motion.div>
  )
}
