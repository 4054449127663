import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'
import ApplicationList from '../components/Membership/ApplicationList'

export default function Applications() {
    const {user} = UserAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if(!user){
            navigate('/signin')
        }
    }, [])

  return (
    <Container id='page' fluid className='p-0'>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: .5 }}>
        <Container className='pt-3 pb-3'>
        <h2 className='d-flex align-items-center justify-content-center' id='heading'>Application List</h2>
        <p className='d-flex align-items-center justify-content-center'>View all current applications for the Altered NWLA program!</p>
        </Container>
        <ApplicationList />
        </motion.div>
    </Container>
  )
}
