import React from 'react'
import { Container, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

export default function ThankYou() {
  return (
    <Container id='page'>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: .5 }}>
            <Container className='pt-3 pb-3'>
                <h2 className='d-flex align-items-center justify-content-center' id='heading'>Thank You For Your Donation!</h2>
                <p className='d-flex align-items-center justify-content-center'>We are nothing without our supporters and are forever grateful for souls such as yourself.</p>
            </Container>
            <Link to='/'>
                <Button>Return to Home</Button>
            </Link>
        </motion.div>
    </Container>
  )
}
