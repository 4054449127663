import { Container } from 'react-bootstrap'
import { motion } from 'framer-motion'
import GalleryList from '../components/Gallery/GalleryList'

export default function Gallery() {
  return (
    <Container id='page'>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2, delay: .5 }}>
      <Container className='pt-3 pb-3'>
        <h2 className='d-flex align-items-center justify-content-center' id='heading'>Our Gallery</h2>
        <p className='d-flex align-items-center justify-content-center'>View our gallery and learn more about Altered NWLA!</p>
        </Container>
        </motion.div>
      <GalleryList />
    </Container>
  )
}
