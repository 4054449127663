import { NavLink } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';
import {Container, Nav, Navbar, Image } from 'react-bootstrap'
import { motion } from "framer-motion"
import UserNav from './UserNav';
import logo3 from '../../images/logo3.png'
import './Navigation.css'

export default function Navigation() {
  const {googleSignIn, user, access} = UserAuth()

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn()
      console.log(user)
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <Container id='nav-container' fluid className='p-0 m-0 bodyFont'>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2 }}>
        <Navbar id='nav' collapseOnSelect expand="lg" variant="dark">
            <Container>
                <Navbar.Brand>
                  <NavLink to='/'>
                  <Image src={logo3} className='img-fluid' height={150} width={150} />
                  </NavLink>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    <motion.div whileHover={{scale: 1.2, marginLeft: '3px', marginRight: '3px', transition: { duration: .7 }}}><NavLink to="/application" className='nav-link'>Application</NavLink></motion.div>
                    {access ? 
                    <motion.div whileHover={{scale: 1.2, marginLeft: '3px', marginRight: '3px', transition: { duration: .7 }}}><NavLink to="/newsletter" className='nav-link'>Newsletter</NavLink></motion.div>
                    :
                    <></>
                    }
                    <motion.div whileHover={{scale: 1.2, marginLeft: '3px', marginRight: '3px', transition: { duration: .7 }}}><NavLink to="/events" className='nav-link'>Break The Glass Events</NavLink></motion.div>
                    <motion.div whileHover={{scale: 1.2, marginLeft: '3px', marginRight: '3px', transition: { duration: .7 }}}><NavLink to="/gps" className='nav-link'>GPS</NavLink></motion.div>
                    <motion.div whileHover={{scale: 1.2, marginLeft: '3px', marginRight: '3px', transition: { duration: .7 }}}><NavLink to="/donations" className='nav-link'>Donations</NavLink></motion.div>
                    <motion.div whileHover={{scale: 1.2, marginLeft: '3px', marginRight: '3px', transition: { duration: .7 }}}><NavLink to="/gallery" className='nav-link'>Gallery</NavLink></motion.div>
                </Nav>
                <Nav>
                  <motion.div>
                    {user ? (
                      <>
                        <Nav.Item>
                          <Container className='p-0'>
                          <UserNav />
                          </Container>
                        </Nav.Item>
                      </>
                    )
                    
                  : (
                    <>
                    </>
                  )
                  }
                    </motion.div>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        </motion.div>
    </Container>
  )
}
