import { useState, useEffect } from 'react'
import { Container, Form, Button, Row, Col, Image, Card } from 'react-bootstrap'
import { storage } from '../../Firebase'
import { ref, uploadBytes, listAll, getDownloadURL, deleteObject } from 'firebase/storage'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { FaTrash } from "react-icons/fa";
import uuid from 'react-uuid';

export default function EventImageForm() {
    const [imageUpload, setImageUpload] = useState(null)
    const [eventList, setEventList] = useState([])
    const eventListRef = ref(storage, "events/")
    const navigate = useNavigate()

    useEffect(() => {
        listAll(eventListRef)
        .then((res) =>{
            res.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setEventList((prev) => [...prev, { url, item }])
                })
            })
        })
    }, [])

    const uploadImage = async () => {
        if(imageUpload == null) return;
        try {
            const imageRef = ref(storage, `events/${imageUpload.name + uuid()}`)
            await uploadBytes(imageRef, imageUpload)
            alert('Success. Your image was uploaded successfully.')
            navigate('/dashboard')
        } catch (error) {
            console.log(error)
            alert('Sorry. There seems to be a failure.')
        }
    }

    const deleteImage = async (item) => {
        try {
            await deleteObject(item);
            setEventList((prev) =>
              prev.filter((image) => image.item.name !== item.name)
            );
            alert('Success. The image was deleted successfully.');
          } catch (error) {
            console.log(error);
            alert('Sorry. There seems to be a failure.');
          }
    }

  return (
    <Container fluid className='p-0'>
        <Container fluid>
            <Card style={{maxWidth: '30rem'}}>
                <Card.Body>
                    <Form style={{maxWidth: '30rem'}}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <h3>Event Flyer Image File Upload</h3>
                            <p>Got a new event flyer to promote? Upload it here!</p>
                            <Form.Label>Event Image File Upload</Form.Label>
                            <Form.Control type="file" placeholder="Upload your image" onChange={(event) => {setImageUpload(event.target.files[0])}} />
                        </Form.Group>
                        <Button onClick={uploadImage} variant="primary">
                            Upload Events Image
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
        <Container className='mt-4' style={{borderTop: 'solid black 1px'}}>
            <h5>Current Events</h5>
            <Row className='mt-2'>
            {eventList.map(({ url, item }) => {
                return (
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: .5 }}>
                        <Col xs={12} md={3}>
                        <Container fluid className='p-0' key={uuid()}>
                            <Image src={url} className='img-fluid'  />
                            <Button className='w-auto mt-2' variant='danger' onClick={() => deleteImage(item)}>
                                <FaTrash />
                            </Button>
                        </Container>
                        </Col>
                    </motion.div>
                )
            })}
            </Row>
        </Container>
    </Container>
  )
}
