import { Container, Card  } from 'react-bootstrap'
import { motion } from 'framer-motion'

export default function GalleryCard(props) {
  return (
    <Container className='m-2 d-flex align-items-center justify-content-center'>
        <motion.div style={{width: '100%'}} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: .3 }}>
        <Card style={{ height: '20rem', width: '20rem', backgroundImage: `url(${props.src})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} className='mx-auto'>
        </Card>
        </motion.div>
    </Container>
  )
}
