import { Container } from 'react-bootstrap'
import Hero from '../components/Hero/Hero'
import About from '../components/About/About'
import Program from '../components/Program/Program'
import Contact from '../components/Contact/Contact'
import Founders from '../components/Founders/Founders'
import FooterAd from '../components/Footer/FooterAd'

export default function Home() {
  return (
    <Container fluid className='p-0' id='page'>
        <Hero />
        <About />
        <Program />
        <Founders />
        <Contact />
        <FooterAd />
    </Container>
  )
}
