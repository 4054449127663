import { useContext, createContext, useEffect, useState } from "react";
import { GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, signOut, onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../Firebase";

const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState()
    const [isAuth, setIsAuth] = useState(false)
    const [isGoogleUser, setIsGoogleUser] = useState(false)
    const [access, setAccess] = useState(false)

     // sign in or login user function
     function signin(email, password) {
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        // ...
        localStorage.setItem("isAuth", true)
        setIsAuth(true);
        setUser(user);
        console.log(user);
  }).catch((error) => {
        console.log(error)
  })
    }

    // sign out or log out user
    function signout() {
        signOut(auth).then(() => {
            // Sign-out successful.
        setUser()
        setIsAuth(false);
        localStorage.removeItem("isAuth")
          })
    }

    // google
    const googleSignIn = () => {
        const provider = new GoogleAuthProvider()
        signInWithPopup(auth, provider)
        .then((result) => {
            const name = result.user.displayName;
            const email = result.user.email;
            const profilePic = result.user.photoURL;    
            
            localStorage.setItem("name", name)
            localStorage.setItem("email", email)
            localStorage.setItem("image", profilePic)
            localStorage.setItem("isAuth", true)
            setIsAuth(true);
            setIsGoogleUser(true)
        }).catch((error) => {
            console.log(error)
        })
    }

    // google
    const googleLogout = () => {
        signOut(auth)
        .then(() => {
            localStorage.removeItem("name")
            localStorage.removeItem("email")
            localStorage.removeItem("image")
            localStorage.removeItem("isAuth")
            setIsAuth(false);
            setIsGoogleUser(false)
            console.log('items removed')
        })
    }

    // reset user password
    const resetUserPassword = async (email) => {
        // run update password function from firebase
        await sendPasswordResetEmail(auth, email).then(() => {
          alert("Password reset link sent to your email.")
        }).catch((error) => {
          // An error ocurred
          console.log(error)
          alert("Sorry, there seems to be an error. Try refreshing the page and try again.")
          return
        });
      }

    const changeAccess = () => {
        if(access) {
            setAccess(false)
            alert('Access to the Newsletter is now disabled.')
        } else {
            setAccess(true)
            alert('Access to the Newsletter is now enabled.')
        }
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser)
            console.log(currentUser)
        })
        return () => {
            unsubscribe()
        }
    }, [])


    return (
        <AuthContext.Provider value={{signin, signout, googleSignIn, googleLogout, user, isAuth, isGoogleUser, access, resetUserPassword, changeAccess}}>
            { children }
        </AuthContext.Provider>
    )
}

export const UserAuth = () => {
    return useContext(AuthContext)
}