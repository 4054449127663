import { Container, Row, Col, Image } from 'react-bootstrap'
import { motion } from 'framer-motion'
import './Founders.css'
import founders from '../../images/founders.jpeg'
import ambassador1 from '../../images/ambassador1.png'
import ambassador2 from '../../images/ambassador2.png'
import ambassador3 from '../../images/ambassador3.png'
import ambassador4 from '../../images/ambassador4.png'
import ambassador5 from '../../images/ambassador5.png'
import AmbassadorList from './AmbassadorList'


export default function Founders() {
  return (
    <motion.div 
    initial={{ opacity: 0 }} 
    animate={{ opacity: 1 }} 
    transition={{ duration: 2, delay: 1 }} 
    >

    <Container fluid className='pb-2 pt-2 bodyFont' id='founders-container'>
        <Container className='d-flex align-items-center justify-content-center pb-2 pt-4'>
            <h2 id='heading'>Meet the Founders</h2>
        </Container>
        <Container fluid className='p-0'>
            <Row>
                <Col sm={12} md={6}>
                <motion.div 
                initial="hidden"
                transition={{ duration: 2, delay: 1 }} 
                whileInView="visible"
                variants={{
                    visible: { opacity: 1, x: 0 },
                    hidden: { opacity: 0, x: -200 }
                  }}
                viewport={{amount: 'some', once: true}}
                >
                 <Container id='image-container' fluid className='p-0 d-flex align-items-center justify-content-center'>
                  <Image src={founders} className='img-fluid' rounded style={{height: '500px', width: 'auto'}} />
                 </Container>
                 </motion.div>
                 </Col>
                <Col sm={12} md={6}>
                  <Container>
                    <h3>“Our passion is inspiring young women to reach higher and dream bigger because when young women are given a chance they can impact their community. “As women it’s painful to see the struggles that most young women are faced with, as we have had our own struggles. It’s only when a woman becomes empowered that she can face her challenges and ALTER her LIFE positively. Young women increase their chances of succeeding when they have Mentors to provide Real-Life examples.”</h3>
                  </Container>
                </Col>
                <AmbassadorList />
            </Row>
        </Container>
    </Container>

    </motion.div>
  )
}
